/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/** Ionic CSS Variables **/
@import "~@ionic/angular/css/core.css";
@import "~animate.css/animate.min.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.css';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
:root {
  /** primary **/
  --ion-font-family: sans-serif;
  --ion-color-primary: #3171e0;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: hsl(23deg 90% 54%);
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
  */
  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;
    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;
    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }
  /*
   * iOS Dark Theme
   * -------------------------------------------
   */
  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-item-background: #000000;
    --ion-card-background: #1c1c1d;
  }
  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #1f1f1f;
    --ion-card-background: #1e1e1e;
  }
}
.transparent {
  --background: transparent !important;
  background: transparent !important;
  box-shadow: none;
}

.label {
  color: var(--nameForm);
}

.h-100 {
  height: 100% !important;
}

.divisorTime {
  width: 97%;
  position: absolute;
  top: 0;
  background: rgb(47, 187, 34);
  border-radius: 4px;
  opacity: 0.8;
  margin-left: 2.5px;
}

:host-context(.item-has-focus.item-label-stacked) .select-icon {
  transform: translate3d(-10px, 0px, 0px) rotate(180deg) !important;
}

.np {
  padding: 0 !important;
}

.zoomContainer {
  --background: transparent;
  backdrop-filter: blur(1.5px);
}

.app.ios .header-ios {
  min-height: 76px;
  padding-top: 20px;
}

.searchbar-input-container.sc-ion-searchbar-ios {
  height: 100%;
}

.hw-100 {
  min-height: 100vh;
}

.m-auto {
  margin: auto;
}

.noShadow {
  box-shadow: none !important;
  --box-shadow: none !important;
}

.btns-admin {
  font-size: 12px;
  text-transform: initial;
}

.alert-tappable.sc-ion-alert-md {
  min-height: 48px !important;
  contain: content !important;
  height: auto !important;
}

.ion-accordion-toggle-icon {
  color: var(--description) !important;
}

.userProof {
  --border-radius: .5rem;
}

.ocultBadge {
  width: 0px;
  min-width: 0;
  transition: all ease 0.5s;
  opacity: 0;
  --padding-end: 0;
  --padding-start: 0;
}

.showBadge {
  --padding-end: 8px;
  --padding-start: 8px;
  opacity: 1;
  width: 32px;
}

.boldText {
  font-weight: bold !important;
}

.noBorder {
  border: none;
}

.btn-aboutme {
  --border-radius: 12px !important;
  --box-shadow: 0px 2px 5px rgb(57 117 1 / 34%);
  border-radius: 12px !important;
  background: #fff;
  text-transform: capitalize;
  margin: 20px 0 5px 0.1rem;
  box-shadow: none;
}

@media screen and (min-width: 1000px) {
  .pasarela-pago {
    --border-radius: 10px 0px 0px 10px;
    --backdrop-opacity: 0.1;
    --max-width: 35% !important;
    --min-height: 90vh;
    justify-content: right;
  }
}
@media screen and (min-width: 578px) {
  .pasarela-pago {
    --border-radius: 10px 0px 0px 10px;
    --backdrop-opacity: 0.1;
    --min-height: 90vh;
    justify-content: right;
  }
}
.more {
  font-size: 1.2rem;
  --padding-start: 1.5rem;
  --padding-end: 1.5rem;
}
.more ion-label {
  text-transform: initial;
}

.w-100 {
  flex-basis: 100%;
  width: 100%;
}

.titleCard {
  font-size: 1.5rem;
  font-weight: 800;
}

.dateEvent {
  cursor: pointer;
  position: relative;
}
.dateEvent::before {
  content: "X";
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
}

.subtitles {
  font-size: 1.8rem;
  font-weight: 800;
}

@media screen and (min-width: 660px) {
  .titleCard {
    font-size: 2rem;
  }
}
.bgCard {
  background-color: var(--card, #fff);
}

.pager {
  margin: 3.5rem 0 !important;
  display: flex;
  justify-content: center;
  margin: auto;
}

.toastMsg {
  --background: var(--header);
  --color: var(--btnHeader);
  color: var(--btnHeader);
}

@media screen and (max-width: 470px) {
  .toastMsg {
    --width: 100vh;
    --border-radius: 0px;
    --min-height: 50px;
    --start: 0;
    --ion-safe-area-bottom: -11px;
  }
}
.noPadding {
  padding: 0;
}

.splash {
  --width: 100vw;
  --height: 100vh;
}

.pointer {
  cursor: pointer !important;
}

.d-flex {
  display: flex;
}

.modalRegister {
  --height: 35%;
  --min-height: 0%;
  --border-radius: 1.8rem;
  --backdrop-opacity: .2;
  --box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
  --width: 95%;
  --background: var(--modals) ;
}

.toolbarUser .searchbar-input {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  -webkit-padding-end: 0;
  padding-inline-end: 0;
}

.notification {
  --min-width: 100%;
  --offset-x: 12.5px;
}

@media screen and (min-width: 578px) {
  .notification {
    --min-width: 400px;
    --backdrop-opacity: 0;
    --offset-x: -1rem;
  }
  .modalRegister {
    --height: 35%;
    --min-height: 0%;
    --border-radius: 1.8rem;
    --backdrop-opacity: .2;
    --box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
    --width: 30%;
  }
  .modalAdmin {
    --height: 60%;
    --min-height: 56%;
    --border-radius: 1.8rem;
    --backdrop-opacity: .2;
    --box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
    --width: 55%;
  }
  .forgottPass {
    --background: transparent;
    --box-shadow: none;
  }
}
.modalColor {
  background-color: var(--modals);
  --background: var(--modals);
}

.indexSup {
  position: relative;
  z-index: 1000;
}

@media screen and (min-width: 578px) {
  .centerPopover {
    --max-height: 400px;
    --min-width: 50%;
    --offset-y: -25%;
    --backdrop-opacity: .2;
  }
}
.centerPopover {
  --max-height: 100%;
}

.noOpacity {
  --backdrop-opacity: 0.1;
}

.color-icon {
  --color: var(--icons);
  color: var(--icons);
}

.swiper-pagination .swiper-pagination-bullet {
  margin: 0 8px !important;
  opacity: 0.5;
}
.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}

ion-content {
  --background: var(--home) ;
}

.sections_content .content_catalogue-text {
  text-align: center;
  padding-top: 1.5rem;
}

.title {
  color: var(--title) !important;
  --color: var(--title) !important;
}

@media screen and (max-width: 420px) {
  .title {
    font-size: 1.2rem;
  }
  .description {
    font-size: 0.8rem;
  }
}
.fc-toolbar-title {
  color: var(--description) !important;
}

.disableDayOfCalendar {
  background: #ccc;
  cursor: not-allowed !important;
}

.description {
  color: var(--description) !important;
  --color: var(--description) !important;
}

.d-block {
  display: block;
}

.fullWdModal {
  --width: 100%;
  --height: 100%;
}

.searchbar .searchbar-search-icon.sc-ion-searchbar-md {
  left: auto;
  z-index: 2;
  right: 16px;
  color: #000;
}

.popover-wide ion-radio {
  --color-checked: var(--title) !important;
}

.searchBarUser {
  border-right: 1.5px solid #aaa;
  border-left: 1.5px solid #aaa;
}
.searchBarUser .searchbar-search-icon.sc-ion-searchbar-md {
  display: none !important;
}

.register-btn {
  width: fit-content;
  margin: auto;
}
.register-btn .border-bottom {
  border-bottom: 1px solid #fff;
}

.ngx-datatable.material {
  border-radius: 0.7rem 0.7rem 0 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.ngx-datatable.material .datatable-header {
  height: auto !important;
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: var(--header);
}
.ngx-datatable.material .datatable-header .datatable-header-cell .sort-btn {
  color: var(--icons);
}
.ngx-datatable.material .datatable-header .datatable-header-cell-label {
  color: var(--btnHeader);
}
.ngx-datatable.material .datatable-body-row {
  height: auto !important;
}
.ngx-datatable.material .datatable-body-row .datatable-body-cell {
  height: auto !important;
  border-bottom: 1px solid #ccc;
}
.ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Activo], .ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Inactivo], .ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Verificado], .ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~="No verificado"] {
  cursor: pointer;
  position: relative;
  color: #fff;
  padding: 0.5rem 1rem;
  z-index: 2;
}
.ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Activo]:hover::before, .ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Inactivo]:hover::before, .ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Verificado]:hover::before, .ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~="No verificado"]:hover::before {
  opacity: 0.9;
}
.ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Activo]::before, .ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Inactivo]::before, .ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Verificado]::before, .ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~="No verificado"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  border-radius: 8px;
  z-index: -1;
  transition: opacity 0.5s ease;
  background-color: var(--primary--background);
}
.ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Activo]::before {
  background-color: var(--primary--background);
}
.ngx-datatable.material .datatable-body-row .datatable-body-cell .datatable-body-cell-label span[title~=Inactivo]::before {
  background-color: var(--primary--background);
}

.rolSpan {
  cursor: pointer;
  position: relative;
  color: #fff;
  padding: 0.5rem 1rem;
  z-index: 2;
}
.rolSpan:hover::before {
  opacity: 0.9;
}
.rolSpan::before {
  content: "";
  background-color: var(--primary--background);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  border-radius: 8px;
  z-index: -1;
  transition: opacity 0.5s ease;
}

.popoverActivo {
  --backdrop-opacity: 0;
  --width: auto;
  --box-shadow: 0 2px 6px #40404066;
}

.checkbox {
  --color-checked: var(--title);
  --background-checked: var(--title);
  --border-color-checked: var(--buttons);
  margin-right: 0.8rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield !important;
}

.checkboxInput {
  --background-checked: var(--primary--background);
  --border-color-checked: var(--primary--background);
  --background: transparent;
  --color: #000;
  --border-color: #ccc;
  margin-right: 0.8rem;
}

:root .select-popover .sc-ion-select-popover-md {
  white-space: normal;
}
:root :host-context(.item-has-focus) {
  transform: translate3d(-10px, 0px, 0px) rotate(180deg) !important;
}

.header {
  background-color: var(--header);
  background: var(--header);
}

.headerCard {
  background-color: var(--title);
}

.headerBtn {
  color: var(--btnHeader) !important;
}

.successComponent {
  --width: 90%;
  --height: 30%;
  --border-radius: 1.2rem;
}

@media screen and (min-width: 769px) {
  .successComponent {
    --width: 35%;
    --height: 35%;
    --border-radius: 1.2rem;
  }
}
.select-search {
  color: #121212;
  --background: rgb(243 243 243);
  --icon-color: #121212;
  --box-shadow: inherit;
}

@media screen and (max-width: 530px) {
  .tached {
    font-size: 12px;
  }
}
.tached {
  text-decoration: line-through;
  font-size: 0.8rem;
  opacity: 0.8;
}

.out-of-service {
  background-color: #f00 !important;
  background: #f00 !important;
}

.fc-blocked-business {
  background-color: rgba(0, 0, 0, 0.2117647059);
  background: var(--fc-non-business-color, rgba(215, 215, 215, 0.5));
}

.underline {
  text-decoration: underline;
}

.alert-radio-label, .alert-checkbox-label {
  white-space: normal !important;
}

.alert-wrapper.sc-ion-alert-md {
  background: var(--card) !important;
}
.alert-wrapper.sc-ion-alert-md h2, .alert-wrapper.sc-ion-alert-md .alert-radio-label, .alert-wrapper.sc-ion-alert-md .alert-checkbox-label {
  color: var(--description) !important;
}

#calendar .unAvailableDate {
  cursor: no-drop !important;
  background: rgba(188, 188, 188, 0.5019607843) !important;
}
#calendar .fc-col-header-cell.fc-day .fc-scrollgrid-sync-inner {
  background: var(--header);
}
#calendar .fc-col-header-cell.fc-day .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  color: var(--btnHeader);
}
#calendar .fc-daygrid-day-number {
  color: var(--description) !important;
}
#calendar .fc-timegrid-slot-label {
  color: var(--description) !important;
}
#calendar .fc-daygrid-day {
  cursor: pointer;
}
#calendar .fc-daygrid-day:hover {
  background: rgba(114, 114, 114, 0.1058823529);
}
#calendar .fc-timegrid-col-events {
  z-index: inherit;
}
#calendar .fc-timegrid-col-events .fc-timegrid-event-harness:has(> a.dateEvent) {
  z-index: 1 !important;
}
#calendar .fc-timegrid-col-events .fc-timegrid-event-harness {
  z-index: 0 !important;
}
#calendar .fc-button-primary {
  background: var(--primary--background) !important;
  box-shadow: var(--primary--box-shadow) !important;
  color: var(--primary--color) !important;
}
#calendar .fc-button-primary:hover {
  opacity: 0.9;
}
#calendar .fc-non-business {
  background-color: rgba(0, 0, 0, 0.2117647059);
}
#calendar .fc-non-business:hover {
  cursor: no-drop;
}
#calendar .reservedEvent {
  cursor: no-drop;
}
#calendar .reservedEvent .fc-event-title {
  font-weight: bold;
}
#calendar .fc-timegrid-slot:hover {
  cursor: pointer !important;
  background: rgba(204, 204, 204, 0.1019607843);
}
#calendar .fc-button-primary:not(:disabled).fc-button-active {
  opacity: 0.8;
}
#calendar .fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-today,
#calendar .fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end {
  background-color: var(--header);
  color: var(--btnHeader);
}

/* Core CSS required for Ionic components to work properly */
/* Basic CSS for apps built with Ionic */
/* Optional CSS utils that can be commented out */
.showOpacity {
  opacity: 1 !important;
}